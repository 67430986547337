<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"/>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <strong class="text-primary">
              Registro de factura manual
            </strong>
          </div>
          <div class="card-body p-2">
            <div class="card">
              <div class="card-header">
                <strong>Seleccionar un Estudiante</strong>
                <div class=" card-header-actions">
                  <form class="form-inline">
                    <b>Filas por p&aacute;gina</b>
                    <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                            v-model="filasPorPagina">
                      <option v-for="option in sizeoptions" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                    <button class="btn btn-outline-dark mr-2" type="button"
                            @click="borrarFiltroBtnOnClick()">
                      <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                      Quitar Filtro
                    </button>
                  </form>
                </div>
              </div>
              <div class="card-body p-0">
                <JqxGrid :width="'100%'" ref="gridSystem"
                         :source="dataAdapter"
                         :autoheight="true" :autorowheight="false" :rowsheight="50"
                         :pageable="true"
                         :pagesize="parseInt(filasPorPagina)"
                         :virtualmode="true"
                         :rendergridrows="rendergridrows"
                         :columns="columns" :enablebrowserselection="true"
                         :enabletooltips="true" :filterable="true"
                         :showfilterrow="true"
                         :sortable="true" @rowclick="gridSystemOnRowSelect($event)"
                         :pagermode="'simple'" :localization="localization"
                         :theme="'bootstrap'" :columnsresize="true"/>
              </div>
            </div>
            <div :style="inscripcion.codigo_alumno>0?'':'display:none'">
              <div class="row">
                <div class="col-6 col-md-3">
                  <CSelect
                      :options="facultades"
                      :plain="true"
                      :value.sync="inscripcion.facultad_id"
                      disabled=""
                      label="Facultad"
                  >
                  </CSelect>
                </div>
                <div class="col-6 col-md-3">
                  <label for="alumnoCarrera">Carrera</label>
                  <select v-if="inscripcion.facultad_id" id="alumnoCarrera"
                          v-model="inscripcion.carrera_id" class="form-control" disabled="">
                    <option v-for="option in carreras[inscripcion.facultad_id]" :value="option.value">{{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.codigo_alumno" label="Codigo de alumno" readonly="" type="text"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.gestion" label="Gestión de ingreso" readonly="" type="text"></CInput>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.apellido1" label="1er Apellido" readonly="" type="text"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.apellido2" label="2do Apellido" readonly="" type="text"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.nombres" label="Nombre(s)" readonly="" type="text"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CSelect
                      :options="nacionalidades"
                      :plain="true"
                      :value.sync="inscripcion.nacionalidad_id"
                      disabled=""
                      label="Nacionalidad"
                  >
                  </CSelect>
                </div>
              </div>
              <hr class="border-primary border-1"/>
              <div class="row">
                <div class="col-12">
                  <strong>Fechas de Vencimiento y Pago de Multas</strong>
                  <i>Costo Multa (Bs.):</i>
                  <input v-model="multa" class="text-center" readonly="" type="text"/>
                </div>
              </div>
              <div class="table-responsive">
                <table cellpadding="0" class="table table-bordered table-sm m-0">
                  <tr>
                    <th></th>
                    <th>1º Cuota</th>
                    <th>2º Cuota</th>
                    <th>3º Cuota</th>
                    <th>4º Cuota</th>
                    <th>5º Cuota</th>
                  </tr>
                  <tr>
                    <td>Fecha</td>
                    <td class="p-0"><input v-model="cuota1.fecha_pago" class="form-control form-control-sm text-center"
                                           type="date"/></td>
                    <td class="p-0"><input v-model="cuota2.fecha_pago" class="form-control form-control-sm text-center"
                                           type="date"/></td>
                    <td class="p-0"><input v-model="cuota3.fecha_pago" class="form-control form-control-sm text-center"
                                           type="date"/></td>
                    <td class="p-0"><input v-model="cuota4.fecha_pago" class="form-control form-control-sm text-center"
                                           type="date"/></td>
                    <td class="p-0"><input v-model="cuota5.fecha_pago" class="form-control form-control-sm text-center"
                                           type="date"/></td>
                  </tr>
                  <tr>
                    <td>Días de Multa</td>
                    <td class="p-0"><input v-model="cuota1.diasmulta" class="form-control form-control-sm text-center"
                                           max="3600" min="0" step="1" type="number"/>
                    </td>
                    <td class="p-0"><input v-model="cuota2.diasmulta" class="form-control form-control-sm text-center"
                                           max="3600" min="0" step="1" type="number"/>
                    </td>
                    <td class="p-0"><input v-model="cuota3.diasmulta" class="form-control form-control-sm text-center"
                                           max="3600" min="0" step="1" type="number"/>
                    </td>
                    <td class="p-0"><input v-model="cuota4.diasmulta" class="form-control form-control-sm text-center"
                                           max="3600" min="0" step="1" type="number"/>
                    </td>
                    <td class="p-0"><input v-model="cuota5.diasmulta" class="form-control form-control-sm text-center"
                                           max="3600" min="0" step="1" type="number"/>
                    </td>
                  </tr>
                  <tr>
                    <td>Multa (Bs.)</td>
                    <td class="p-0"><input v-model="cuota1.multa" class="form-control form-control-sm text-center"
                                           max="10000" min="0" step="0.1" type="number"/>
                    </td>
                    <td class="p-0"><input v-model="cuota2.multa" class="form-control form-control-sm text-center"
                                           max="10000" min="0" step="0.1" type="number"/>
                    </td>
                    <td class="p-0"><input v-model="cuota3.multa" class="form-control form-control-sm text-center"
                                           max="10000" min="0" step="0.1" type="number"/>
                    </td>
                    <td class="p-0"><input v-model="cuota4.multa" class="form-control form-control-sm text-center"
                                           max="10000" min="0" step="0.1" type="number"/>
                    </td>
                    <td class="p-0"><input v-model="cuota5.multa" class="form-control form-control-sm text-center"
                                           max="10000" min="0" step="0.1" type="number"/>
                    </td>
                  </tr>
                </table>
              </div>
              <hr class="border-primary border-1"/>
              <form ref="formCreate" novalidate>
                <div class="row mb-3">
                  <div class="col-2">
                    <label class="form-label" for="factura_nit">Nit Cliente</label>
                    <input id="factura_nit" v-model="factura.factura_nit" class="form-control"
                           type="text" @change="buscarnit()"/>
                  </div>
                  <div class="col-4">
                    <label class="form-label" for="factura_nombre"><strong>Nombre cliente:</strong>
                      <template v-if="factura.factura_nombre!=''">
                        <a href="javascript:return;" class="text-danger underline" @click="modalCliente=true"> [
                          <font-awesome-icon :icon="['fa', 'pencil']"/> Modificar datos del cliente ]</a>
                      </template>
                    </label>
                    <input id="factura_nombre" v-model="factura.factura_nombre"
                           class="form-control" type="text"/>
                  </div>
                  <div class="col-2">
                    <label class="form-label" for="fecha_venta">Fecha factura</label>
                    <input id="fecha_venta" class="form-control" type="date"
                           v-model="factura.fecha_venta" required>
                  </div>
                  <div class="col-2">
                    <label class="form-label" for="factura_numero">Número de factura</label>
                    <input id="factura_numero" class="form-control" type="number"
                           min="1" value="1000000" v-model="factura.factura_numero" required>
                  </div>
                  <div class="col-2">
                    <label class="form-label" for="factura_autorizacion">Autorización</label>
                    <input id="factura_autorizacion" v-model="factura.factura_autorizacion" class="form-control" type="text">
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <label class="form-label" for="gestion">Gestión:</label>
                    <select class="form-control pl-1 pr-1" id="gestion"
                            v-model="factura.gestion" required="required">
                      <option value="" selected>:: SELECCIONAR ::</option>
                      <template v-for="(option,key) in gestiones">
                        <optgroup :label="key">
                          <option v-for="suboption in option" :value="suboption.value">
                            {{ suboption.label }}
                          </option>
                        </optgroup>
                      </template>
                    </select>
                  </div>
                  <div class="col-2">
                    <CSelect
                        :options="moneda"
                        :plain="true"
                        :value.sync="factura.moneda"
                        label="Moneda:"
                        readonly="readonly"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-3">
                    <label class="form-label" for="tipocambio">Tipo de cambio:</label>
                    <input id="tipocambio" v-model="factura.tipo_cambio" class="form-control" readonly type="text">
                  </div>
                  <div class="col-3">
                    <CSelect
                        :options="tipoCobro"
                        :plain="true"
                        :value.sync="factura.tipo_cobro_id"
                        label="Tipo de Cobro"
                        required="required"
                        class=" pb-0 mb-1"
                        @change="actualizarpagos"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2" v-if="factura.tipo_cobro_id!=tipoEfectivo">
                    <label class="form-label" for="tipocambio">Deposito:</label>
                    <input id="deposito" v-model="factura.deposito" class="form-control"
                           type="text" @change="actualizarDepositos">
                  </div>
                </div>
                <hr class="mt-1 mb-1"/>
                <div class="row pb-0 mb-0">
                  <div class="col-1 text-center">
                    Item
                  </div>
                  <div class="col-3 pl-0 pr-0 text-center">
                    A cobrar
                  </div>
                  <div class="col-2 text-center">
                    Monto
                    <font-awesome-icon icon="fa-solid fa-circle-question"
                                       v-c-tooltip="'Si los montos de las <u>cuotas</u> (<u>1,2,3,4,5</u>) no son correctos debe actualizar el <b>contrato</b> del estudiante'"/>
                  </div>
                  <div class="col-1 pl-0 pr-0 text-center">
                    T. Cobro
                  </div>
                  <div class="col-2 pl-0 pr-0 text-center">
                    Depósito
                  </div>
                  <div class="col-2 text-center">
                    Observaciones
                  </div>
                  <div class="col-1 pl-0">
                  </div>
                </div>
                <div class="row pb-0 mb-0">
                  <div class="col-1 text-center pl-0 pr-1">1</div>
                  <div class="col-3 pl-0 pr-1">
                    <CSelect
                        :options="conceptoPago"
                        :plain="true"
                        :value.sync="factura.concepto_id_1"
                        label=""
                        @change="actualizaMonto(1)"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input id="monto_1" v-model="factura.monto_1" autocomplete="false"
                           class="" step="0.1" type="number"
                           v-bind:class="[factura.monto_1 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <CSelect
                        :options="tipoCobro"
                        :plain="true"
                        :value.sync="factura.tipo_cobro_id_1"
                        label=""
                        required="required"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <template >
                      <input id="deposito_1" v-model="factura.deposito_1" class="form-control"
                             type="text" v-if="factura.tipo_cobro_id_1 !== tipoEfectivo">
                    </template>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input id="observacion_1" v-model="factura.observacion_1"
                           class="form-control" maxlength="350"
                           type="text">
                  </div>
                  <div class="col-1 pl-0">
                    <button :class="factura.monto_1 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                            :disabled="factura.monto_1 < 1" @click="limpiar(1)"
                    >Limpiar
                    </button>
                  </div>
                </div>
                <div class="row pb-0 mb-0">
                  <div class="col-1 text-center pl-0 pr-1">2</div>
                  <div class="col-3 pl-0 pr-1">
                    <CSelect
                        :options="conceptoPago"
                        :plain="true"
                        :value.sync="factura.concepto_id_2"
                        label=""
                        @change="actualizaMonto(2)"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.monto_2" autocomplete="false"
                           class="" step="0.1" type="number"
                           v-bind:class="[factura.monto_2 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <CSelect
                        :options="tipoCobro"
                        :plain="true"
                        :value.sync="factura.tipo_cobro_id_2"
                        label=""
                        required="required"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <template >
                      <input id="deposito_2" v-model="factura.deposito_2" class="form-control"
                             type="text" v-if="factura.tipo_cobro_id_2 !== tipoEfectivo">
                    </template>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.observacion_2" class="form-control" maxlength="350" type="text">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <button :class="factura.monto_2 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                            :disabled="factura.monto_2 < 1" @click="limpiar(2)"
                    >Limpiar
                    </button>
                  </div>
                </div>
                <div class="row pb-0 mb-0">
                  <div class="col-1 text-center pl-0 pr-1">3</div>
                  <div class="col-3 pl-0 pr-1">
                    <CSelect
                        :options="conceptoPago"
                        :plain="true"
                        :value.sync="factura.concepto_id_3"
                        label=""
                        @change="actualizaMonto(3)"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.monto_3" autocomplete="false"
                           class="" step="0.1" type="number"
                           v-bind:class="[factura.monto_3 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <CSelect
                        :options="tipoCobro"
                        :plain="true"
                        :value.sync="factura.tipo_cobro_id_3"
                        label=""
                        required="required"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <template >
                      <input id="deposito_3" v-model="factura.deposito_3" class="form-control"
                             type="text" v-if="factura.tipo_cobro_id_3 !== tipoEfectivo">
                    </template>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.observacion_3" class="form-control" maxlength="350" type="text">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <button :class="factura.monto_3 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                            :disabled="factura.monto_3 < 1" @click="limpiar(3)"
                    >Limpiar
                    </button>
                  </div>
                </div>
                <div class="row pb-0 mb-0">
                  <div class="col-1 text-center pl-0 pr-1">4</div>
                  <div class="col-3 pl-0 pr-1">
                    <CSelect
                        :options="conceptoPago"
                        :plain="true"
                        :value.sync="factura.concepto_id_4"
                        label=""
                        @change="actualizaMonto(4)"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.monto_4" autocomplete="false"
                           class="" step="0.1" type="number"
                           v-bind:class="[factura.monto_4 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <CSelect
                        :options="tipoCobro"
                        :plain="true"
                        :value.sync="factura.tipo_cobro_id_4"
                        label=""
                        required="required"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <template >
                      <input id="deposito_4" v-model="factura.deposito_4" class="form-control"
                             type="text" v-if="factura.tipo_cobro_id_4 !== tipoEfectivo">
                    </template>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.observacion_4" class="form-control" maxlength="350" type="text">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <button :class="factura.monto_4 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                            :disabled="factura.monto_4 < 1" @click="limpiar(4)"
                    >Limpiar
                    </button>
                  </div>
                </div>
                <div class="row pb-0 mb-0">
                  <div class="col-1 text-center pl-0 pr-1">5</div>
                  <div class="col-3 pl-0 pr-1">
                    <CSelect
                        :options="conceptoPago"
                        :plain="true"
                        :value.sync="factura.concepto_id_5"
                        label=""
                        @change="actualizaMonto(5)"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.monto_5" autocomplete="false"
                           class="" step="0.1" type="number"
                           v-bind:class="[factura.monto_5 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <CSelect
                        :options="tipoCobro"
                        :plain="true"
                        :value.sync="factura.tipo_cobro_id_5"
                        label=""
                        required="required"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <template >
                      <input id="deposito_5" v-model="factura.deposito_5" class="form-control"
                             type="text" v-if="factura.tipo_cobro_id_5 !== tipoEfectivo">
                    </template>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.observacion_5" class="form-control" maxlength="350" type="text">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <button :class="factura.monto_5 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                            :disabled="factura.monto_5 < 1" @click="limpiar(5)"
                    >Limpiar
                    </button>
                  </div>
                </div>
                <div class="row pb-0 mb-0">
                  <div class="col-1 text-center pl-0 pr-1">6</div>
                  <div class="col-3 pl-0 pr-1">
                    <CSelect
                        :options="conceptoPago"
                        :plain="true"
                        :value.sync="factura.concepto_id_6"
                        label=""
                        @change="actualizaMonto(6)"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.monto_6" autocomplete="false"
                           class="" step="0.1" type="number"
                           v-bind:class="[factura.monto_6 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <CSelect
                        :options="tipoCobro"
                        :plain="true"
                        :value.sync="factura.tipo_cobro_id_6"
                        label=""
                        required="required"
                        class=" pb-0 mb-1"
                    >
                    </CSelect>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <template >
                      <input id="deposito_6" v-model="factura.deposito_6" class="form-control"
                             type="text" v-if="factura.tipo_cobro_id_6 !== tipoEfectivo">
                    </template>
                  </div>
                  <div class="col-2 pl-0 pr-1">
                    <input v-model="factura.observacion_6" class="form-control" maxlength="350" type="text">
                  </div>
                  <div class="col-1 pl-0 pr-1">
                    <button :class="factura.monto_6 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                            :disabled="factura.monto_6 < 1" @click="limpiar(6)"
                    >Limpiar
                    </button>
                  </div>
                </div>
                <div class="row pb-0 mb-0">
                  <div class="col-4">
                    <div class="row">
                      <div class="col-1 text-center"></div>
                      <div class="col-11 pl-0 pr-1 text-right text-end">
                        <strong>MONTO TOTAL</strong>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pl-0 pr-1 text-center">
                    <strong>{{ montoTotal }}</strong>
                  </div>
                  <div class="col-1 pl-0 pr-1 d-none"></div>
                  <div class="col-2 pl-0 pr-1 d-none"></div>
                  <div class="col-3 pl-0 pr-1"></div>
                  <div class="col-1 pl-0 pr-1"></div>
                </div>
              </form>
            </div>
          </div>
          <CCardFooter>
          <span :style="inscripcion.codigo_alumno>0?'':'display:none'">
          <CButton class="mr-2" color="primary" @click="confirmarGrabar()">Registrar Factura Manual</CButton>
          </span>
            <CButton color="danger" @click="goBack">Salir sin generar factura</CButton>
          </CCardFooter>
        </div>
      </transition>
    </div>
    <CModal
        :show.sync="modalCliente"
        :no-close-on-backdrop="true"
        :centered="true"
        :closeOnBackdrop="false"
        title="Crear cliente"
        size="lg"
        color="dark"
    >
      <template #header>
        Registrar cliente
      </template>
      <form ref="frmNuevoCliente" novalidate @submit.prevent="GrabarCliente">
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="codigoTipoDocumentoIdentidad">Tipo Documento
            Identidad</label>
          <div class="col-sm-8">
            <select id="codigoTipoDocumentoIdentidad"
                    v-model="cliente.codigoTipoDocumentoIdentidad" class="form-control form-select"
                    required="required" @change="actualizaMonto(6)">
              <option v-for="option in TipoDocumentoIdentidad" :value="option.value">
                {{ option.label }}
              </option>
            </select>
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="numeroDocumento">Número Documento de Identidad</label>
          <div class="col-sm-5">
            <input id="numeroDocumento" v-model="cliente.numeroDocumento" class="form-control"
                   required="required" maxlength="350"
                   type="text">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
          <div class="col-sm-3">
            <img src="/img/carnet.jpg" class="img-responsive" style="width: 100%"/>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="complemento">Complemento</label>
          <div class="col-sm-5">
            <input id="complemento" v-model="cliente.complemento" class="form-control"
                   maxlength="5" type="text" placeholder="Número para diferenciar duplicados">
            <small><em><strong>No escribir: </strong>La Paz, Oruro, Cochabamba, etc</em></small>
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
          <div class="col-sm-3">
            <img src="/img/complemento.jpg" class="img-responsive" style="width: 100%"/>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="nombreRazonSocial">Nombre / Razon Social</label>
          <div class="col-sm-8">
            <input id="nombreRazonSocial" v-model="cliente.nombreRazonSocial"
                   class="form-control" maxlength="350"
                   required="required" type="text">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="email">Email</label>
          <div class="col-sm-8">
            <input id="email" v-model="cliente.email"
                   class="form-control" maxlength="350"
                   required="required" type="email">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="telefono">Celular</label>
          <div class="col-sm-8">
            <input id="telefono" v-model="cliente.telefono"
                   class="form-control" max="99999999999" min="0"
                   required="required" type="number">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <button class="btn btn-success" type="submit">Grabar datos del Cliente</button>
            <button aria-label="Close" class="btn btn-outline-dark ml-3 ms-3" data-bs-dismiss="modal"
                    type="button" @click="cancelarGrabarCliente">Regresar a la pantalla anterior
            </button>
          </div>
        </div>
      </form>
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Table from "@/views/base/Table";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";

var sourceLst;
var modalPdf;
var moment = require('moment');
var pagesizeoptions = [3,6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'EditUser',
  components: {
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    Table,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      modalCliente:false,
      inscripcion: {
        facultad_id: 0,
        carrera_id: 0,
        codigo_alumno: '',
        gestion: '',
        apellido1: '',
        apellido2: '',
        nombres: '',
        genero_id: 0,
        fecha_nacimiento: '',
        nacionalidad_id: 0,
        documento_identificacion: '',
        traspaso_id: 0,
        estado_civil_id: 0,
        trabaja_id: 0,
        direccion: '',
        telefono: '',
        email: '',
        casilla: '',
      },
      facultades: [], carreras: [],
      generos: [], estados_civiles: [],
      nacionalidades: [], si_nos: [],
      maxdate: moment(new Date()).add(-12 * 10, 'M').format("YYYY-MM-DD"),
      observacion: '', tipoEfectivo: 0,
      multa: 2,
      cuota1: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},
      cuota2: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},
      cuota3: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},
      cuota4: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},
      cuota5: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},

      factura: {
        factura_nit: '',
        factura_nombre: '',
        factura_autorizacion: '',
        carrera_id: 0,
        codigo_alumno: '',
        gestion: '',
        moneda_id: 0,
        tipo_cambio: 0,
        semestre: '',
        tipo_cobro_id: 0,
        sede_id: 0,
        deposito: '',
        concepto_id_1: 0, monto_1: '', observacion_1: '', tipo_cobro_id_1:0, deposito_1:'',
        concepto_id_2: 0, monto_2: '', observacion_2: '', tipo_cobro_id_2:0, deposito_2:'',
        concepto_id_3: 0, monto_3: '', observacion_3: '', tipo_cobro_id_3:0, deposito_3:'',
        concepto_id_4: 0, monto_4: '', observacion_4: '', tipo_cobro_id_4:0, deposito_4:'',
        concepto_id_5: 0, monto_5: '', observacion_5: '', tipo_cobro_id_5:0, deposito_5:'',
        concepto_id_6: 0, monto_6: '', observacion_6: '', tipo_cobro_id_6:0, deposito_6:'',
      },
      conceptoPago: [], moneda: [], tipoCobro: [], gestiones: [],

      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sizeoptionsSelect: '',

      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '9%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '14%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '14%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '14%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '25%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: 'Gestion', datafield: "gestion", width: '7%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Gestion"});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '5%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Estado"});
          }
        },
        {
          text: '', datafield: "editar", width: '12%', cellsrenderer: function (row, column, value) {
            return '<button class="btn btn-success" type="button">Seleccionar</button>';
          }, filterable: false, sortable: false
        },
        {text: '', datafield: "facultad_id", hidden: true},
        {text: '', datafield: "carrera_id", hidden: true},
        {text: '', datafield: "nacionalidad_id", hidden: true},
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
      cliente : {
        id: 0,
        numeroDocumento: 0,
        complemento: '',
        codigoCliente: '',
        nombreRazonSocial: '',
        codigoTipoDocumentoIdentidad: 0,
        email: '',
        telefono: ''
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/inscripcion?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'gestion', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'facultad_id', type: "int"},
        {name: 'carrera_id', type: "int"},
        {name: 'nacionalidad_id', type: "int"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'apellido1',
      sortdirection: 'asc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  computed: {
    montoTotal: function () {
      let sum = 0;
      sum += parseFloat('0'+(modalPdf.factura.monto_1??0))+parseFloat('0'+modalPdf.factura.monto_2)+
          parseFloat('0'+modalPdf.factura.monto_3)+parseFloat('0'+modalPdf.factura.monto_4)+
          parseFloat('0'+modalPdf.factura.monto_5)+parseFloat('0'+modalPdf.factura.monto_6);
      sum = Math.round(sum*100)/100
      return sum
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    confirmarGrabar() {
      if (!modalPdf.$refs.formCreate.checkValidity()) {
        modalPdf.$refs.formCreate.classList.add('was-validated');
        modalPdf.$refs.mensajeToast.makeToast('Error', 'Algunos datos son requeridos.', 'danger');
      } else {
        modalPdf.$refs.confirm.confirm('¿Esta segur@ de grabar la factura?', 'Después de este paso no podrá realizar más cambios',
            modalPdf.store,function (){});
      }
    },
    store() {
      modalPdf.$refs.alert.show("Grabando datos");
      modalPdf.factura.codigo_alumno = modalPdf.inscripcion.codigo_alumno;
      modalPdf.factura.carrera_id = modalPdf.inscripcion.carrera_id;
      modalPdf.factura.sede_id = modalPdf.sedeSeleccionada;
      axios.post(this.$apiAdress + '/api/facturamanual/store?token=' + localStorage.getItem("api_token"),
          modalPdf.factura
      )
          .then(function (response) {
            modalPdf.inscripcion = {
              facultad_id: 0,
              carrera_id: 0,
              codigo_alumno: '',
              gestion: '',
              apellido1: '',
              apellido2: '',
              nombres: '',
              genero_id: 0,
              fecha_nacimiento: '',
              nacionalidad_id: 0,
              documento_identificacion: '',
              traspaso_id: 0,
              estado_civil_id: 0,
              trabaja_id: 0,
              direccion: '',
              telefono: '',
              email: '',
              casilla: '',
            };
            modalPdf.$refs.mensajeToast.makeToast('Info', 'Registro creado satisfactoriamente.', 'success');
            modalPdf.$refs.alert.hide();
            modalPdf.goBack();
            //modalPdf.reportePdf(response.data.factura_id,'pdf',modalPdf.goBack);
          })
          .catch(function (error) {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    gridSystemOnRowSelect: function (event) {
      this.inscripcion = this.$refs.gridSystem.getrowdata(event.args.rowindex);
      this.opcionesfactura();

    },
    actualizarmultas() {
      axios.get(
          modalPdf.$apiAdress + '/api/facturaenlinea/' + this.inscripcion.codigo_alumno + '/' + modalPdf.factura.gestion + '/actualizamultas?token=' + localStorage.getItem("api_token")
      )
          .then((response) => {
            if (response.data.cuota1) {
              modalPdf.cuota0 = response.data.cuota0;
              modalPdf.cuota1 = response.data.cuota1;
              modalPdf.cuota2 = response.data.cuota2;
              modalPdf.cuota3 = response.data.cuota3;
              modalPdf.cuota4 = response.data.cuota4;
              modalPdf.cuota5 = response.data.cuota5;
            } else {
              modalPdf.cuota0 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota1 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota2 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota3 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota4 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota5 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
            }
            modalPdf.factura.factura_nit = response.data.factura_nit;
            modalPdf.factura.factura_nombre = response.data.factura_nombre;
            modalPdf.multa = response.data.multa;
            modalPdf.contrato = response.data.contrato;
            modalPdf.actualizaMonto(1);
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    async buscarnit() {
      // axios.get(
      //     modalPdf.$apiAdress + '/api/factura/' + modalPdf.factura.factura_nit + '/buscarnit?token=' + localStorage.getItem("api_token")
      // )
      //     .then((response) => {
      //       modalPdf.factura.factura_nombre = response.data;
      //     })
      //     .catch(function (error) {
      //       modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
      //     });
      let self = this;
      await axios.get(this.$apiAdress + '/api/facturaenlinea/' + self.factura.factura_nit + '/buscarnitci?token=' + localStorage.getItem("api_token")
      ).then(function (response) {
        self.cliente = {
          id: 0,
          numeroDocumento: 0,
          complemento: '',
          codigoCliente: '',
          nombreRazonSocial: '',
          codigoTipoDocumentoIdentidad: 0,
          email: '',
          telefono: ''
        };
        try {
          if (response.data.status && response.data.nitci!==null && response.data.nitci.id > 0) {
            self.factura.factura_nombre = response.data.nitci.nombreRazonSocial;
            self.cliente = response.data.nitci
          } else {
            self.cliente.numeroDocumento = self.factura.factura_nit
            if (response.data.nitci.length > 0) {
              self.cliente = response.data.nitci
            }
            self.modalCliente=true
          }
        } catch (e) {
          self.cliente.numeroDocumento = self.factura.factura_nit
          self.modalCliente=true
        }
      }).catch(function (error) {
        modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        self.modalCliente=true
      });
    },

    buscaMonto(concepto_id) {
      switch (concepto_id) {
        case this.cuota1.id:
          if (this.cuota1.multa > 0) {
            this.observacion = "Cuota (Bs.- " + this.cuota1.monto + ") + Multa por " + this.cuota1.diasmulta + " día[s] (Bs.- " + this.cuota1.multa + ")";
          }
          return this.cuota1.total;
          break;
        case this.cuota2.id:
          if (this.cuota2.multa > 0) {
            this.observacion = "Cuota (Bs.- " + this.cuota2.monto + ") + Multa por " + this.cuota2.diasmulta + " día[s] (Bs.- " + this.cuota2.multa + ")";
          }
          return this.cuota2.total;
          break;
        case this.cuota3.id:
          if (this.cuota3.multa > 0) {
            this.observacion = "Cuota (Bs.- " + this.cuota3.monto + ") + Multa por " + this.cuota3.diasmulta + " día[s] (Bs.- " + this.cuota3.multa + ")";
          }
          return this.cuota3.total;
          break;
        case this.cuota4.id:
          if (this.cuota4.multa > 0) {
            this.observacion = "Cuota (Bs.- " + this.cuota4.monto + ") + Multa por " + this.cuota4.diasmulta + " día[s] (Bs.- " + this.cuota4.multa + ")";
          }
          return this.cuota4.total;
          break;
        case this.cuota5.id:
          if (this.cuota5.multa > 0) {
            this.observacion = "Cuota (Bs.- " + this.cuota5.monto + ") + Multa por " + this.cuota5.diasmulta + " día[s] (Bs.- " + this.cuota5.multa + ")";
          }
          return this.cuota5.total;
          break;
      }
    },
    actualizaMonto(item) {
      this.observacion = '';
      switch (item) {
        case 1:
          this.factura.monto = this.buscaMonto(this.factura.concepto_id);
          this.factura.observacion = this.observacion;
          break;
        case 2:
          this.factura.monto_2 = this.buscaMonto(this.factura.concepto_id_2);
          this.factura.observacion_2 = this.observacion;
          break;
        case 3:
          this.factura.monto_3 = this.buscaMonto(this.factura.concepto_id_3);
          this.factura.observacion_3 = this.observacion;
          break;
        case 4:
          this.factura.monto_4 = this.buscaMonto(this.factura.concepto_id_4);
          this.factura.observacion_4 = this.observacion;
          break;
        case 5:
          this.factura.monto_5 = this.buscaMonto(this.factura.concepto_id_5);
          this.factura.observacion_5 = this.observacion;
          break;
        case 6:
          this.factura.monto_6 = this.buscaMonto(this.factura.concepto_id_6);
          this.factura.observacion_6 = this.observacion;
          break;
      }
    },
    actualizarpagos(){
      this.factura.tipo_cobro_id_1=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_2=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_3=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_4=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_5=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_6=this.factura.tipo_cobro_id;
    },
    actualizarDepositos(){
      this.factura.deposito_1=this.factura.deposito;
      this.factura.deposito_2=this.factura.deposito;
      this.factura.deposito_3=this.factura.deposito;
      this.factura.deposito_4=this.factura.deposito;
      this.factura.deposito_5=this.factura.deposito;
      this.factura.deposito_6=this.factura.deposito;
    },
    reportePdf(factura_id) {
      modalPdf.$refs.alert.show('Descargando documento');
      axios.get(
          modalPdf.$apiAdress + '/api/factura/' + factura_id + '/facturapdf?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
          })
          .catch(function (error) {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    limpiar(fila) {
      switch (fila) {
        case 0:
          this.factura.concepto_id = "";
          this.factura.monto = "";
          this.factura.observacion = "";
          break;
        case 2:
          this.factura.concepto_id_2 = "";
          this.factura.monto_2 = "";
          this.factura.observacion_2 = "";
          break;
        case 3:
          this.factura.concepto_id_3 = "";
          this.factura.monto_3 = "";
          this.factura.observacion_3 = "";
          break;
        case 4:
          this.factura.concepto_id_4 = "";
          this.factura.monto_4 = "";
          this.factura.observacion_4 = "";
          break;
        case 5:
          this.factura.concepto_id_5 = "";
          this.factura.monto_5 = "";
          this.factura.observacion_5 = "";
          break;
        case 6:
          this.factura.concepto_id_6 = "";
          this.factura.monto_6 = "";
          this.factura.observacion_6 = "";
          break;
      }
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    opcionesfactura()
    {
      axios.get(this.$apiAdress + '/api/facturaenlinea/' + this.sedeSeleccionada + '/'+this.inscripcion.codigo_alumno+'/opcionesfactura?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.moneda = response.data.moneda;
            let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
            modalPdf.conceptoPago = valorPorDefecto.concat(response.data.conceptoPago);
            modalPdf.tipoCobro = response.data.tipoCobro;
            for (let tip in modalPdf.tipoCobro) {
              if (modalPdf.tipoCobro[tip].label == "EFECTIVO") {
                modalPdf.tipoEfectivo = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_1 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_2 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_3 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_4 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_5 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_6 = modalPdf.tipoCobro[tip].value
              }
            }
            modalPdf.gestiones = response.data.gestiones;
            modalPdf.factura.moneda_id = response.data.moneda[0].value;
            modalPdf.factura.concepto_id_1 = response.data.conceptoPago[1].value;
            modalPdf.factura.tipo_cobro_id = response.data.tipoCobro[0].value;
            modalPdf.factura.gestion = response.data.mesActual;
            modalPdf.TipoDocumentoIdentidad = response.data.TipoDocumentoIdentidad;
            //console.log(modalPdf.TipoDocumentoIdentidad)
            modalPdf.actualizarmultas()
          })
          .catch(function (error) {
            console.log(error)
            modalPdf.$refs.mensajeToast.makeToast('Error', error.response.data.message, 'danger');
          });
    },
    GrabarCliente() {
      if (!this.$refs.frmNuevoCliente.checkValidity()) {
        this.$refs.frmNuevoCliente.classList.add('was-validated');
        modalPdf.$refs.mensajeToast.makeToast('', 'Algunos datos son requeridos.', 'danger');
      } else {
        let self = this
        this.modalCliente=false
        modalPdf.$refs.alert.show("Grabando datos");
        this.$refs.confirm.confirm('¿Esta segur@ de grabar el nuevo cliente?', '', function () {
          axios.post(this.$apiAdress + '/api/facturaenlinea/' + self.sedeSeleccionada + '/grabarcliente?token=' + localStorage.getItem("api_token"), self.cliente
          ).then(function (response) {
            modalPdf.$refs.alert.hide();
            if (response.data.cliente.id > 0) {
              self.factura.factura_nit = response.data.cliente.numeroDocumento;
              self.factura.factura_nombre = response.data.cliente.nombreRazonSocial;
              modalPdf.$refs.mensajeToast.makeToast('', 'Cliente Registrado satisfactoriamente', 'success')
            }
          }).catch(function (error) {
            this.modalCliente=true
            modalPdf.$refs.mensajeToast.makeToast('', error.response.data.message, 'danger');
          });
        }, this.cancelarGrabarCliente);
      }
    },
    cancelarGrabarCliente() {
      this.modalCliente=false
      this.factura.factura_nit = ''
      this.cliente = {}
    },
  },
  mounted: function () {
    modalPdf = this;
    axios.get(this.$apiAdress + '/api/inscripcion/' + localStorage.sedeSeleccionada + '/create?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.facultades = valorPorDefecto.concat(response.data.facultades);
          modalPdf.carreras = response.data.carreras;
          modalPdf.generos = valorPorDefecto.concat(response.data.generos);
          modalPdf.estados_civiles = valorPorDefecto.concat(response.data.estados_civiles);
          modalPdf.nacionalidades = valorPorDefecto.concat(response.data.nacionalidades);
          modalPdf.si_nos = valorPorDefecto.concat(response.data.si_nos);
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}
</script>